export default function FetchError() {
  return (
    <div className="d-flex align-items-center">
      <div className="mx-auto p-4 border rounded-3">
        <b>An Error Occurred:</b>
        <p>
          Could not fetch the data. Please try again later.<br/>
          Or contact <a href="mailto:info@memomove.eu">info@memomove.eu</a>
        </p>
      </div>
    </div>
  )
}