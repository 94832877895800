import assign from 'object-assign'
import EventEmitter from 'events'

let showPoi = false
let pois = []

const SHOW_POI_CHANGE = 'onShowPoiChange'
const POI_CHANGE = 'onPoiChange'

const PoiStore = assign({}, EventEmitter.prototype, {
  showPoi: function() {
    showPoi = true
    this.emit(SHOW_POI_CHANGE)
  },

  getShowPoi: function() {
    return showPoi
  },

  addShowListener: function(callback) {
    this.on(SHOW_POI_CHANGE, callback)
  },

  removeShowListener: function(callback) {
    this.removeListener(SHOW_POI_CHANGE, callback)
  },

  getPois: function() {
    return pois
  },

  setPois: function(newPois) {
    pois = newPois ? newPois : []
    this.emit(POI_CHANGE)
  },

  addPoisListener: function(callback) {
    this.on(POI_CHANGE, callback)
  },

  removePoiListener: function(callback) {
    this.removeListener(POI_CHANGE, callback)
  }
})

export default PoiStore
