import assign from 'object-assign'
import EventEmitter from 'events'

const DISTANCE_CHANGED = "onDistanceChange"
const END_CHANGED = "onEndChange"
const PAUSE_CHANGED = "onPauseChange"
// const POSITION_CHANGED = "onPositionChange"
// const STEPS_CHANGED = "onStepsChange"
const SPEED_CHANGED = "onSpeedChange"
const STEP_INDEX_CHANGED = "onStepIndexChange"

// let totalTime = 0
let totalDistance = 0
let atEnd = false
let paused = false
let forwardSpeed = 3000
let currentStepIndex = 0

// let currentPosition = {
//   lat: null,
//   lng: null,
//   heading: null
// }

// const steps = []

const MemoStore = assign({}, EventEmitter.prototype, {
  getForwardSpeed: function() {
    return forwardSpeed
  },

  resetForwardSpeed: function() {
    forwardSpeed = 3000
  },

  changeForwardSpeed: function(speed) {
    forwardSpeed = speed
    this.emit(SPEED_CHANGED)
  },

  addForwardSpeedChangeListener: function(callback) {
    this.on(SPEED_CHANGED, callback)
  },

  removeForwardSpeedChangeListener: function(callback) {
    this.removeListener(SPEED_CHANGED, callback)
  },

  // addStep: function(step) {
  //   steps.push(step)
  //   this.emit(STEPS_CHANGED);
  //   totalDistance += step.distanceMoved
  //   this.emit(DISTANCE_CHANGED);
  // },

  // getSteps: function() {
  //   return steps
  // },

  // addPositionChangeListener: function(callback) {
  //   this.on(POSITION_CHANGED, callback)
  // },

  // removePositionChangeListener: function(callback) {
  //   this.removeListener(POSITION_CHANGED, callback)
  // },

  addDistanceChangeListener: function(callback) {
    this.on(DISTANCE_CHANGED, callback)
  },

  removeDistanceChangeListener: function(callback) {
    this.removeListener(DISTANCE_CHANGED, callback)
  },

  addEndChangeListener: function(callback) {
    this.on(END_CHANGED, callback)
  },

  removeEndChangeListener: function(callback) {
    this.removeListener(END_CHANGED, callback)
  },

  addPauseChangeListener: function(callback) {
    this.on(PAUSE_CHANGED, callback)
  },

  removePauseChangeListener: function(callback) {
    this.removeListener(PAUSE_CHANGED, callback)
  },

  addDistance: function(distance) {
    totalDistance += distance
    this.emit(DISTANCE_CHANGED);
  },

  getTotalDistance: function() {
  	return totalDistance
  },

  resetDistance: function() {
    totalDistance = 0
  },

  setEndStatus: function(endStatus) {
    atEnd = endStatus
    this.emit(END_CHANGED);
  },

  getEndStatus: function() {
    return atEnd
  },

  setPaused: function(newVal) {
    paused = newVal
    this.emit(PAUSE_CHANGED);
  },

  getPaused: function () {
    return paused
  },

  setCurrentStepIndex: function(newStepIndex) {
    currentStepIndex = newStepIndex
    this.emit(STEP_INDEX_CHANGED)
  },

  getCurrentStepIndex: function() {
    return currentStepIndex
  },

  addStepIndexChangeListener: function(callback) {
    this.on(STEP_INDEX_CHANGED, callback)
  },

  removeStepIndexChangeListener: function(callback) {
    this.removeListener(STEP_INDEX_CHANGED, callback)
  }

  // getCurrentPosition: function() {
  //   return currentPosition
  // }
})

export default MemoStore
