import { useTranslation } from "react-i18next"
import { capitalizeFirstLetter } from "../Helpers"

export default function PermissionError({ errorCode, handleLogout }) {
  const containerStyles = {
    height: '100vh',
    width: '100vw'
  }

  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center" style={containerStyles}>
      <div className="mx-auto p-4 alert alert-danger bg-white">
        <h1 className="mb-4">
          {/* Error: {errorCode} */}
          { t('something went wrong')}
        </h1>
        <p>{ t('access denied') }</p>
        <div className="mt-4 d-flex align-items-center">
          <button onClick={() => handleLogout()} className='btn btn-primary'>
            { capitalizeFirstLetter(t('sign out')) }
          </button>

          <a href="mailto:info@memomove.eu" subject="Memomove Admin - Permission problems" className="ms-auto fs-small">
            { t('contact us') }
          </a>
        </div>
      </div>
    </div>
  )
}
