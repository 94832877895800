import { useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MemoStore from "../../../stores/MemoStore";

export default function Finish() {
    const { t } = useTranslation('translation')
    const [ end, setEnd ] = useState(false)

    const getEndStatus = useCallback(() => {
        const atEnd = MemoStore.getEndStatus()
        setEnd(atEnd)
    }, [])
    

    useEffect(() => {
        MemoStore.addEndChangeListener(getEndStatus)
        return () => {
            MemoStore.removeEndChangeListener(getEndStatus)
        }
    }, [getEndStatus])

    const imgStyle = {
        position: 'relative',
        height: '35%',
        left: '50% ',
        transform: 'translateX(-50%)',
    };

    //By Vlad Kochelaevskiy Checkered Flags, Finish Line, Finish 83KB 960x609
    //http://diysolarpanelsv.com/finish-flag-clipart.html
    return (
        <>
            {end && (
                <div className="position-absolute w-100" style={{ zIndex: 10, top: '10%' }}>
                    <div className="d-flex flex-column mx-auto w-25 bg-white bg-opacity-50 p-4 rounded-3">
                        <h1 className="route-finish-text">
                            <span>
                                {t('end of route')}
                            </span>
                        </h1>
                        <h1 className="route-finish-text">
                            <span>
                                {t('congratulations')}
                            </span>
                        </h1>
                        <img src="/static/img/finish.png" style={imgStyle} alt="finish"/>
                        <Link to="/routes" className="route-finish-text d-inline-block btn btn-primary mt-4">
                            {t('back to routes')}
                        </Link>
                    </div>
                </div>
            )}
        </>
    )
}
