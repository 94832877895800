import { useEffect, useState, useCallback } from "react";
import MemoStore from '../../stores/MemoStore';

export default function Distance() {
  let [ distance, setDistance ] = useState(0)

  const getTotalDistance = useCallback(() => {
    const totalDistance = MemoStore.getTotalDistance()
    setDistance(Math.round(totalDistance));
  }, [])

  useEffect(() => {
    MemoStore.addDistanceChangeListener(getTotalDistance);
  }, [getTotalDistance])

  const divStyle = {
    // position: 'absolute',
    // zIndex: 10,
    right: 'calc( 62.5% + 5px )',
    // bottom: '60px',
    fontFamily: 'Verdana, Geneva, sansSerif',
    fontWeight: 'bold',
    // fontStyle: 'normal',
    // fontSize: '45px',
    opacity: '0.75',
    backgroundColor: '#e7e7e7',
    borderRadius: '7px',
  };

  return (
    <div style={divStyle} className="px-2 fs-2">
      {distance}m
    </div>
  );
}
