import MemoStore from "../../../stores/MemoStore"
import { useState, useEffect, useCallback, useRef } from "react"
import { destinationName } from "../../Helpers"

export default function RouteTrajectory({ steps, legs, orderedSteps }) {
  const [ timeLeft, setTimeLeft ] = useState()
  // const [ currentIndex, setCurrentIndex ] = useState(0)
  // const [ nextPoiIndex, setNextPoiIndex ] = useState()
  const [ currentLegIndex, setCurrentLegIndex ] = useState(0)
  const [ currentLegStepIndex, setCurrentLegStepIndex] = useState(0)
  const [ currentStepIndex, setCurrentStepIndex ] = useState(0)
  const stepDistanceMoved = useRef(0)
  const trajectoryRef = useRef()
  const stepRef = useRef(null)

  const formatDuration = useCallback((duration) => {
    const s = duration % (60)
    const m = (duration - s) / 60

    if (m === 1 && s === 0) {
      return `60"`
    } else if (m === 0) {
      return ` ${s}"`
    } else {
      return `${m}' ${s}"`
    }
  }, [])

  const formattedTime = useCallback(() => {
    const s = timeLeft / 1000 % (60)
    const m = (timeLeft / 1000 - s) / 60

    if (m === 1 && s === 0) {
      return `60"`
    } else if (m === 0) {
      return ` ${s}"`
    } else {
      return `${m}' ${s}"`
    }
  }, [timeLeft])

  const calcTimeLeft = useCallback(() => {
    // console.log('steps: ', steps);
    const currentStepIndex = MemoStore.getCurrentStepIndex()
    const leftSteps = steps.slice(currentStepIndex).length
    const forwardSpeed = MemoStore.getForwardSpeed()

    setTimeLeft(leftSteps * forwardSpeed)
  }, [steps, setTimeLeft])

  // const checkNextPoi = useCallback(() => {
  //   const totalDistance = MemoStore.getTotalDistance()
  //   const nextPoi = steps.find(step => step.distanceFromStart >= totalDistance && step.pois)
  //   console.log('nextPoi: ', nextPoi);
  //   console.log('currentStepIndex: ', currentStepIndex);

  //   const currentLeg = orderedSteps[currentStepIndex]
  //   console.log('currentLeg: ', currentLeg);
  //   if (nextPoi) {
  //     setNextPoiIndex(currentLeg.indexOf(nextPoi))
  //   } else {
  //     setNextPoiIndex(undefined)
  //   }
  // }, [steps, setNextPoiIndex, currentStepIndex, orderedSteps])

  const getMap = useCallback(() => {
    if (!stepRef.current) {
      stepRef.current = new Map()
    }
    return stepRef.current
  }, [])

  const scrollToStep = useCallback(() => {
    const map = getMap();
    // const step = orderedSteps[currentLegIndex][currentLegStepIndex]
    const step = steps[currentStepIndex]
    const node = map.get(step);
    // console.log('node: ', node);
    node.scrollIntoView({
      behavior: "smooth",
      // block: "nearest",
      block: "center",
      inline: "center",
    });
  // }, [orderedSteps, currentLegIndex, currentLegStepIndex, getMap])
}, [steps, currentStepIndex, getMap])

  const updateLegStep = useCallback(() => {
    // console.log('stepDistanceMoved: ', stepDistanceMoved.current);
    // const currentLeg = legs[currentLegIndex]
    // // orderedSteps[currentLegIndex].find()

    // // if (stepDistanceMoved.current <= currentLeg.steps[currentLegStepIndex].distance.value) {
    // if (stepDistanceMoved.current <= currentLeg.distance.value) {
    // // if (stepDistanceMoved.current <= orderedSteps[currentLegIndex][currentLegStepIndex].distance.value) {
    //   const currentStepIndex = MemoStore.getCurrentStepIndex()
    //   stepDistanceMoved.current += steps[currentStepIndex].distance_moved
    //   // setCurrentStepIndex(currentStepIndex)
    //   setCurrentLegStepIndex(currentLegStepIndex + 1)
    // // } else if (currentLeg.steps.length + 1 >= currentLegStepIndex + 1) {
    //   // setCurrentLegStepIndex(currentLegStepIndex + 1)
    //   // setCurrentStepIndex(0)
    //   stepDistanceMoved.current = 0
    // } else {
    //   setCurrentLegIndex(currentLegIndex + 1)
    //   setCurrentStepIndex(0)
    //   stepDistanceMoved.current = 0
    // }

    const nextIndex = currentStepIndex + 1
    setCurrentStepIndex(nextIndex)
    // console.log('currentStepIndex: ', nextIndex);

    scrollToStep()

    // console.log('stepDistanceMoved: ', stepDistanceMoved.current);
  }, [legs, currentLegIndex, setCurrentLegIndex, currentLegStepIndex, setCurrentLegStepIndex, steps, stepDistanceMoved, scrollToStep])

  const updatePositionIndications = useCallback(() => {
    calcTimeLeft()
    // checkNextPoi()
    // highLightStep()
    updateLegStep()
  }, [calcTimeLeft, updateLegStep])

  useEffect(() => {
    // console.log('currentLegIndex: ', currentLegIndex);
    // console.log('currentLegStepIndex: ', currentLegStepIndex);
    // console.log('orderedSteps: ', orderedSteps);

    calcTimeLeft()
    // checkNextPoi()
    MemoStore.addStepIndexChangeListener(updatePositionIndications)

    return () => {
      MemoStore.removeStepIndexChangeListener(updatePositionIndications)
    }
  }, [calcTimeLeft, updatePositionIndications, currentLegIndex, currentLegStepIndex, orderedSteps])

  return (
    <div className="bg-white d-flex flex-column h-100">
      <div className="border-bottom d-flex">
        <h1 className="d-flex align-items-center px-4 py-2 mx-auto">
          <img src="/static/img/finish.png" className="me-4" style={{ width: '4rem' }} alt="finish"/>
          {formattedTime()}
        </h1>
      </div>

      <div className="h-100">
        {/* {legs.map((leg, index) => ( */}
          <div
            // key={index}
            className="d-flex flex-column h-100 px-4 py-2"
          >
            <small className="fw-bold text-center mb-4">
              {destinationName(legs[0].start_address)}
            </small>
            <div
              ref={trajectoryRef}
              className="my-auto d-flex flex-column align-items-center flex-grow-1 overflow-y-scroll"
              id="routeTrajectory"
              style={{ height: 'calc(60vh)' }}
            >
              <div className="d-flex flex-column my-auto">
                {/* {leg.distance.text}<br/> */}

                {/* {leg.steps.map((legStep, index) => (
                  <div className={`${currentLegStepIndex === index ? ' text-primary' : ''}`}>
                    {legStep.distance.text} - {legStep.distance.value}
                  </div>
                ))} */}
                {/* {legs.map((leg, index) => ( */}
                  {/* <div key={index}> */}
                    {/* {orderedSteps[index].map((step, i) => ( */}
                    {steps.map((step, i) => (
                      <div
                        key={`${i}`}
                        className="d-flex flex-column align-items-center"
                        ref={(node) => {
                          const map = getMap()
                          if (node) {
                            map.set(step, node)
                          } else {
                            map.delete(step)
                          }
                        }}
                      >
                        <div className={`${i === 0 ? 'rounded-top-5' : ''}${i === steps.length - 1 ? 'rounded-bottom-5' : ''} ${currentStepIndex === i ? 'bg-primary' : 'bg-secondary-subtle'}`} style={{ width: '.75rem', height: '.75rem' }}>
                        </div>

                        {step.pois && (
                          <div className={`text-center d-flex px-2 py-1 rounded-4 border align-items-center mb-1 ${currentStepIndex <= i ? 'text-primary' : 'text-secondary'}`}>
                            <i className="fas fa-map-marker"></i>
                            <small className="ms-2">
                              {formatDuration(step.poisDuration)}
                            </small>
                          </div>
                        )}
                      </div>
                    ))}
                  {/* </div> */}
                {/* ))} */}
              </div>
            </div>
            <small className="fw-bold text-center mt-4">
              {destinationName(legs[legs.length -1].end_address)}
            </small>
          </div>
        {/* ))} */}
      </div>
    </div>
  )
}