import { useCallback, useEffect, useRef } from "react"
import Timer from './Timer'
import Finish from './Finish'
import Distance from '../../Views/Distance'
import Pause from "./Pause"
import MemoStore from '../../../stores/MemoStore';
import SpeedSlider from "./SpeedSlider"
import callApi from "../../../utils/ApiCall"
import PoiButton from "./PoiButton"
import Poi from "./Poi"
import PoiStore from "../../../stores/PoiStore"

export default function Map({ id, steps, defaultSpeed }) {
  const mapRef = useRef()
  const sv = useRef()
  // const center = useRef()
  // const zoomLevel = 14
  const currentStep = useRef()
  const timeBetweenSteps = useRef()
  const moveForwardInterval = useRef()
  // const keyListener = useRef()
  const timerInterval = useRef()
  // const moveForwardLastStart = useRef()
  const panorama = useRef()

  const updateTotalDistance = useCallback((distance) => {
    MemoStore.addDistance(distance)
  }, [])

  const mapStyle = {
    width: 'auto',
    height: '100%'
  }

  const setPanoramaImage = useCallback(async (pos) => {
    // console.log('map: ', map);
    // console.log(panorama);
    panorama.current.setOptions({
      addressControl: false,
      imageDateControl: false,
      clickToGo: false,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      scrollWheel: false,
      panControl: false,
      motionTracking: false,
      zoomControl: false,
      enableCloseButton: false,
      keyboardShortcuts: false,
      panningGesturesEnabled: false,
      zoomGesturesEnabled: false
    })
    // ; // TODO fix type
    // console.log('pos: ', pos);
    // updateTotalDistance(pos.distance_moved)

    // panorama.setPosition(pos.location)
    // panorama.setPano(pos.pano_id)
    // panorama.setPov(
    //   /** @type {google.maps.StreetViewPov} */ {
    //     // heading: 265,
    //     heading: pos.heading,
    //     pitch: pos.pitch,
    //   },
    // )
    // panorama.setVisible(true);

    sv.current.getPanorama({
      location: pos.location,
      preference: 'best',
      sources: ['outdoor']
    }).then((data, status) => {
      // setTimeout(async() => {
        // panorama.current.setPosition(step)
        panorama.current.setPano(pos.pano_id)
        panorama.current.setPov(
          /** @type {google.maps.StreetViewPov} */ {
            heading: pos.heading,
            pitch: pos.pitch,
          },
        )
        panorama.current.setVisible(true)
        updateTotalDistance(pos.distance_moved)
      // }, 1000)
    }).catch(() => {
      console.error("Street View data not found for this location.")
    })
  }, [panorama, updateTotalDistance])

  const pauseTimer = useCallback(() => {
    clearInterval(timerInterval.current)
    // console.log('timerInterval.current: ', timerInterval.current);
  }, [])

  const pauseMoveForward = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    // console.log('pauseMoveForward: ', moveForwardLastStart.current);
  }, [moveForwardInterval])

  const setEnd = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    MemoStore.setEndStatus(true)

    pauseTimer()
  }, [moveForwardInterval, pauseTimer])

  const moveForward = useCallback(() => {
    // console.log('moveForward...');
    const currentIndex = steps.findIndex(step => step === currentStep.current)
    const nextStep = steps[currentIndex+1]

    if (nextStep) {
      currentStep.current = nextStep
      MemoStore.setCurrentStepIndex(currentIndex+1)
      PoiStore.setPois(nextStep.pois)
      setPanoramaImage(nextStep)
    } else {
      setEnd()
    }
  }, [steps, currentStep, setPanoramaImage, setEnd])

  const StartMoveForwardInterval = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    moveForwardInterval.current = setInterval(() => {
      // moveForwardLastStart.current = performance.now()

      moveForward()
    }, timeBetweenSteps.current);
  }, [moveForwardInterval, moveForward, timeBetweenSteps])

  const changeForwardInterval = useCallback(() => {
    timeBetweenSteps.current = MemoStore.getForwardSpeed()

    if (MemoStore.getPaused()) return
    // const timeSpent = performance.now() - moveForwardLastStart.current
    // setTimeout(() => {
      // moveForward()
      StartMoveForwardInterval()
    // }, timeBetweenSteps.current - timeSpent);
  // }, [timeBetweenSteps, moveForward, StartMoveForwardInterval])
  }, [StartMoveForwardInterval])

  const toggleMoveForward = useCallback(() => {
    const paused = MemoStore.getPaused()
    if (paused) {
      pauseMoveForward()
    } else {
      StartMoveForwardInterval()
    }
  }, [pauseMoveForward, StartMoveForwardInterval])
  
  const toggleStreetView = useCallback(() => {
    setPanoramaImage(currentStep.current)

    StartMoveForwardInterval()
  }, [setPanoramaImage, StartMoveForwardInterval, currentStep])

  const onKey = useCallback((e) => {
    // const curmovement = new Date();
    // console.log('curmomvement: ', curmovement);
    // this.lastmovement = curmovement;

    // console.log(e);
    e.stopPropagation()

    // window.addEventListener(
    //   'keydown',
    //   (event) => {
    //     if (
    //       (
    //         // Change or remove this condition depending on your requirements.
    //         event.key === 'ArrowUp' || // Move forward
    //         event.key === 'ArrowDown' || // Move forward
    //         event.key === 'ArrowLeft' || // Pan left
    //         event.key === 'ArrowRight' || // Pan right
    //         event.key === '+' || // Zoom in
    //         event.key === '=' || // Zoom in
    //         event.key === '_' || // Zoom out
    //         event.key === '-' // Zoom out
    //       ) &&
    //       !event.metaKey &&
    //       !event.altKey &&
    //       !event.ctrlKey
    //     ) {
    //       event.stopPropagation()
    //     };
    //   },
    //   { capture: true },
    // );

    const intKey = (window.Event) ? e.which : e.keyCode;
    switch (intKey) {
      // case 37:
      // case 65:
      // case 81:
      //   // q or a
      //   this.turnLeft();
      //   break;
      // case 39:
      // case 68:
      //   // d
      //   this.turnRight();
      //   break;
      case 38:
      case 87:
      case 90:
        // w or z
        // moveForward();
        break;
      default:
        break;
    }
  }, [moveForward])

  const createMap = useCallback(async() => {
    // center.current = currentStep.current.location

    // const map = new window.google.maps.Map(mapRef.current, {
    //   // center: setCenter(),
    //   center: center.current,
    //   zoom: zoomLevel,
    //   streetViewControl: false,
    //   fullscreenControl: false,
    //   disableDefaultUI: true,
    //   keyboardShortcuts: false,
    //   gestureHandling: "none",
    //   zoomControl: false
    // })
    // const customStyled = [
    //   {
    //     featureType: "poi",
    //     elementType: "labels",
    //     stylers: [
    //       { visibility: "off" }
    //     ]
    //   }
    // ]
    // map.set('styles', customStyled)
    
    // // console.log('map.current: ', mapRef.current);
    // mapRef.current = map

    // panorama = map.getStreetView()

    if (!sv.current) {
      sv.current = new window.google.maps.StreetViewService()
    }
    if (!panorama.current) {
      panorama.current = new window.google.maps.StreetViewPanorama(mapRef.current)
    }

    const forwardSpeed = MemoStore.getForwardSpeed()
    timeBetweenSteps.current = forwardSpeed

    toggleStreetView()
  }, [toggleStreetView])

  const setLastRun = useCallback(async () => {
    const endStatus = MemoStore.getEndStatus()
    if (!endStatus) return

    const url = `/api/routes/${id}`

    await callApi(url, 'PUT', {})
      .then(function (response) {
        // console.log('setLastRun', response)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }, [id])

  useEffect(() => {
    if (steps.length === 0) return

    MemoStore.resetDistance()

    // MemoStore.resetForwardSpeed()
    MemoStore.changeForwardSpeed(defaultSpeed * 1000)
    MemoStore.setEndStatus(false)

    currentStep.current = steps[0]
    // MemoStore.setCurrentStepIndex(0)
    PoiStore.setPois(steps[0].pois)
    createMap()

    window.addEventListener('keydown', (event) => onKey(event), { capture: true })
    MemoStore.addPauseChangeListener(toggleMoveForward)
    MemoStore.addForwardSpeedChangeListener(changeForwardInterval)
    MemoStore.addEndChangeListener(setLastRun)

    return () => {
      window.removeEventListener('keydown', (event) => onKey(event), { capture: true })
      MemoStore.removePauseChangeListener(toggleMoveForward)
      MemoStore.removeForwardSpeedChangeListener(changeForwardInterval)
      MemoStore.removeEndChangeListener(setLastRun)
      if (moveForwardInterval.current) clearInterval(moveForwardInterval.current)
    }
  }, [steps, createMap, onKey, currentStep, toggleMoveForward, changeForwardInterval, moveForwardInterval, setLastRun, defaultSpeed])

  return (
    <div className="w-100 h-100">
      <Poi />

      <Finish />
      <Pause />

      {/* Add Mini map... */}
      {/* <div ref={miniMapRef} id="miniMap" style={miniMapStyle} /> */}

      <div className="position-absolute z-2 w-100 d-flex justify-content-between" style={{ bottom: '50px' }}>
        <div className="d-flex w-100 ms-4 align-items-center">
          <div className="">
            <Timer
              timerInterval={timerInterval}
              pauseTimer={pauseTimer}
            />
            <Distance />
          </div>

          <div className="mx-auto">
            <PoiButton />
          </div>

          <SpeedSlider
            defaultSpeed={defaultSpeed}
          />
        </div>
      </div>

      <div ref={mapRef} id="map" style={mapStyle} />
    </div>
  )
}
