import React from 'react'
import CycleCountries from './CycleCountries'
import RouteCategoryCard from '../Views/RouteCategoryCard'
import Loader from '../Views/Loader'
import FetchError from '../Views/FetchError'

import {
  Routes,
  Route } from 'react-router-dom'
import callApi from '../../utils/ApiCall';

class CycleRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: '',
      isLoadingStats: true,
      freeCycleRoutesStats: {},
      privateCycleRoutesStats: {},
      mySharedCycleRoutesStats: {},
      otherSharedCycleRoutesStats: {}
    }

    this.setActivePage = this.setActivePage.bind(this)
    this.nav_link_classes = this.nav_link_classes.bind(this)
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.props.setPageClasses('Routes')
    this.fetchCycleRoutesStats()
  }

  componentDidUpdate() {
    this.props.setPageClasses('Routes')

    const {
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats
    } = this.state

    if (Object.keys(freeCycleRoutesStats).length === 0 ||
        Object.keys(privateCycleRoutesStats).length === 0 || 
        Object.keys(mySharedCycleRoutesStats).length === 0 ||
        Object.keys(otherSharedCycleRoutesStats).length === 0) {
      this.fetchCycleRoutesStats()
    }
  }

  setActivePage(page) {
    this.setState({ activePage: page })
  }

  setLoadingCycleRoutes() {
    this.setState({ isLoadingCycleRoutes: true, cycleRoutes: [] })
  }

  nav_link_classes(link) {
    // let default_classes = 'd-flex card px-4 py-2 link-underline link-underline-opacity-0 col-2'
    let default_classes = 'd-flex card px-4 py-2 link-underline link-underline-opacity-0 col'
    const active_class = ' text-bg-primary'
    if (link !== 'Other Shared') {
      default_classes += ' me-4'
    }
    return this.state.activePage === link ? default_classes + active_class : default_classes
  }

  reFetchCycleRoutes() {
    console.log('reFetchCycleRoutes');
    this.setState({ isLoadingStats: true}, async () => {
      await this.fetchCycleRoutesStats()
    })
  }

  async fetchCycleRoutesStats() {
    if (!this.state.isLoadingStats) return

    // console.log('fetchCycleRoutesStats');
    let urlBase = '/api/routes/stats'

    const freeCycleRoutesStats = await this.fetchStats(`${urlBase}/free`)
    const privateCycleRoutesStats = await this.fetchStats(`${urlBase}/private`)

    let mySharedCycleRoutesStats
    let otherSharedCycleRoutesStats
    if (this.props.organisation.community === 'Sh') {
      mySharedCycleRoutesStats = await this.fetchStats(`${urlBase}/my/shared`)
      otherSharedCycleRoutesStats = await this.fetchStats(`${urlBase}/other/shared`)
    } else {
      mySharedCycleRoutesStats = []
      otherSharedCycleRoutesStats= []
    }

    this.setState({
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats,
      isLoadingStats: false
    // }, () => {
      // this.setState({ isLoadingStats: false })
    })
  }

  async fetchStats(url) {
    return await callApi(url)
    // return await axios.get(`${process.env.REACT_APP_API_URL}${url}`)
      .then(function (response) {
        return { ...response.data }
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)

        return errorResponse.success
      });
  }

  render () {
    const {
      isLoadingStats,
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats
    } = this.state

    const { organisation, authType, t } = this.props

    return (
      <>
        <div className='d-flex align-items-center'>
          <h1>
            Routes
          </h1>
        </div>
        
        {isLoadingStats ? (
          <Loader />
        ) : (
          <>
            {!freeCycleRoutesStats || !privateCycleRoutesStats || !mySharedCycleRoutesStats || !otherSharedCycleRoutesStats ? (
              <FetchError />
            ) : (
              <>
                {/* <div className="d-flex align-items-center"> */}
                <div className="row g-0">
                  <RouteCategoryCard
                    name='Free'
                    url='/free'
                    countries_count={freeCycleRoutesStats.countries_count}
                    route_count={freeCycleRoutesStats.route_count}
                    nav_link_classes={this.nav_link_classes}
                    t={t}
                  />
                  <RouteCategoryCard
                    name='Private'
                    url='/private'
                    countries_count={privateCycleRoutesStats.countries_count}
                    route_count={privateCycleRoutesStats.route_count}
                    nav_link_classes={this.nav_link_classes}
                    t={t}
                  />
                  {organisation.community === 'Sh' && (
                    <>
                      <RouteCategoryCard
                        name='My Shared'
                        url='/my/shared'
                        countries_count={mySharedCycleRoutesStats.countries_count}
                        route_count={mySharedCycleRoutesStats.route_count}
                        nav_link_classes={this.nav_link_classes}
                        t={t}
                      />
                      <RouteCategoryCard
                        name='Other Shared'
                        url='/other/shared'
                        countries_count={otherSharedCycleRoutesStats.countries_count}
                        route_count={otherSharedCycleRoutesStats.route_count}
                        nav_link_classes={this.nav_link_classes}
                        t={t}
                      />
                    </>
                  )}
                </div>

                <Routes>
                  <Route
                    path='/free/*'
                    element={
                      <CycleCountries
                        type='Free'
                        urlSuffix='free'
                        setActivePage={this.setActivePage}
                        t={t}
                      />
                    }
                  />
                  <Route
                    path='/private/*'
                    element={
                      <CycleCountries
                        type='Private'
                        urlSuffix='private'
                        setActivePage={this.setActivePage}
                        authType={authType}
                        orgUuid={organisation.uuid}
                        t={t}
                      />
                    }
                  />
                  <Route
                    path='/my/shared/*'
                    element={
                      <CycleCountries
                        type='My Shared'
                        urlSuffix='my/shared'
                        setActivePage={this.setActivePage}
                        authType={authType}
                        orgUuid={organisation.uuid}
                        t={t}
                      />
                    }
                  />
                  <Route
                    path='/other/shared/*'
                    element={
                      <CycleCountries
                        type='Other Shared'
                        urlSuffix='other/shared'
                        setActivePage={this.setActivePage}
                        authType={authType}
                        orgUuid={organisation.uuid}
                        t={t}
                      />
                    }
                  />
                </Routes>
              </>
            )}
          </>
        )}
      </>
    )
  }
}

export default CycleRoutes
