import React from "react";
import callApi from '../../../utils/ApiCall';
import StreetViewPlayer from "./StreetViewPlayer";
import Loader from "../../Views/Loader";
import RouteInfo from "./RouteInfo"
import RouteTrajectory from "./RouteTrajectory"

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoadingStartLocation: true,
      isLoadingSteps: true,
      current_step: null,
      totalDistanceMoved: 0,
      isEnd: false,
      allowMoveForward: true,
      startLocation: null,
      waypoints: [],
      overview_path: [],
      result: [],
      steps: [],
      routeFound: false,
      legs: [],
      orderedSteps: []
      // showMap: DashboardStore.getShowMap(),
      // liteMode: SettingsStore.getLiteModeEnabled(),
      // memorideversion: SettingsStore.getMemorideVersion()
    };
    // this._onDashboardChange = this._onDashboardChange.bind(this)
    this.setNextStep = this.setNextStep.bind(this)

    this.current_step_index = 0
    // this.current_step = null
    // this.totalDistanceMoved = 0
  }

  async componentDidMount() {
    const { id } = this.props;
    await this.loadRoute(id)
  }

  // componentWillUnmount() {
  // }

  // componentDidUpdate() {
  //   StatsStore.stopTimer()
  // }

  async loadRoute(id) {
    const that = this

    const { authType } = this.props
    let url
    if (authType === 'Manager') {
      // url = `/api/${orgUuid}/routes/${id}`
      url = `/api/v2/manager/routes/${id}`
    } else {
      url = `/api/v2/routes/${id}`
    }

    await callApi(url)
      .then(function (response) {
        if (!response.data) {
          // setIsLoadingStartLocation(false)
          return
        }

        // console.log('loadRoute: ', response)

        let distanceMoved = 0
        const steps = [ ...response.data.steps ]
        steps.forEach(step => {
          distanceMoved += step.distance_moved
          step.distanceFromStart = distanceMoved

          if (step.pois) {
            const totalDuration = step.pois.reduce((total, p) => total + p.duration, 0)
            step.poisDuration = totalDuration
          }
        })

        that.setState({
          startLocation: response.data.start_point,
          endLocation: response.data.end_point,
          waypoints: response.data.waypoints,
          // overview_path: response.data.overview_path.flat(),
          // result: response.data.result.reverse()
          description: response.data.description,
          distance: response.data.distance,
          internal_distance: response.data.internal_distance,
          steps: steps,
          legs: response.data.directions.routes[0].legs,
          default_speed: response.data.default_speed
        }, () => {
          // that.setState({ isLoadingStartLocation: false })
          // Handle route not found...
          if (!that.state.steps) {
            that.setState({ isLoadingSteps: false })
          } else {
            // const orderedSteps = that.handleSteps()
            that.setState({ current_step: that.state.steps[0], routeFound: true, isLoadingSteps: false })
            // StatsStore.startTimer()
            // console.log('Starting route...');
            // const { description, internal_distance } = that.state
    
            // that.props.setRouteInfoData(true, { description, internal_distance})
          }
          // that.setLastRun(id)
          // that.current_step = that.state.steps[0]
        })

      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        that.setState({ isLoadingSteps: false })
      });
  }

  setNextStep() {
    // console.log('settings Next Step...');
    // console.log(this.state.current_step);

    this.current_step_index += 1
    const nextStep = this.state.steps[this.current_step_index]
    const that = this

    this.setState({
      current_step: nextStep,
      totalDistanceMoved: parseFloat(this.state.totalDistanceMoved) + parseFloat(nextStep.distance_moved),
      isEnd: this.current_step_index === this.state.steps.length - 1,
      allowMoveForward: this.current_step_index !== this.state.steps.length - 1,
    }, () => {
      that.checkEnd()
    })
  }

  async checkEnd() {
    if (this.state.isEnd) {
      await this.setLastRun()
      // RouteStore.setReplayOff()
      // StatsStore.stopTimer()
    }
  }

  async setLastRun() {
    const { id, authType } = this.props
    let url
    if (authType === 'Manager') {
      // url = `/api/${orgUuid}/routes/${id}`
      return
    } else {
      url = `/api/routes/${id}`
    }

    await callApi(url, 'PUT', {})
      .then(function (response) {
        // console.log(response)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }

  // _onDashboardChange() {
  //   this.setState({
  //     showMap: DashboardStore.getShowMap()
  //   });
  // }

  handleSteps() {
    let orderedSteps = []
    const steps = this.state.steps

    console.log('steps: ', steps);
    
    const legs = this.state.legs
    console.log('legs: ', legs);

    let stepStartIndex = 0
    for (let index = 0; index < legs.length; index++) {
      const leg = legs[index];
      const legDistance = leg.distance.value
      // console.log(index);
      console.log('legDistance: ', legDistance);
      let distanceMoved = 0
      if (stepStartIndex >= steps.length) return

      if (index === 0) {
        console.log(legDistance);
        console.log('distanceMoved: ', distanceMoved);
        const subSteps = []
        steps.every((step, i) => {
          // if (distanceMoved >= legDistance && legs.length > index + 1) {
          if (distanceMoved <= legDistance) {
            subSteps.push(step)
            distanceMoved += step.distance_moved
            return true
          } else {
            stepStartIndex = i + 1
            console.log('stepStartIndex: ', stepStartIndex);
            return false
          }
          // return
          //   const legSteps = steps.slice(stepStartIndex, i)
          //   orderedSteps.push(legSteps)
          //   stepStartIndex = i + 1
          //   distanceMoved = 0
          //   console.log('created first leg-list: ', stepStartIndex);
          //   distanceMoved += step.distance_moved
          //   return true
          // }
          // console.log('distanceMoved: ', distanceMoved);
        })
        console.log('subSteps: ', subSteps);
        orderedSteps.push(subSteps)
      } else {
        // console.log('stepStartIndex: ', stepStartIndex);
        // const stepsPart = steps.slice(stepStartIndex)
        // stepsPart.forEach((step, i) => {
        //   distanceMoved += step.distance_moved
        //   if (distanceMoved > legDistance) {
        //     const legSteps = steps.slice(stepStartIndex, i - 1)
        //     orderedSteps.push(legSteps)
        //     stepStartIndex = i
        //     distanceMoved = 0
        //     return false
        //   }
        //   // console.log('distanceMoved: ', distanceMoved);
        // })
        console.log('stepStartIndex: ', stepStartIndex);
        const subSteps = []
        const stepsPart = steps.slice(stepStartIndex)
        stepsPart.every((step, i) => {
          // if (distanceMoved >= legDistance && legs.length > index + 1) {
          if (distanceMoved <= legDistance) {
            subSteps.push(step)
            distanceMoved += step.distance_moved
            return true
          } else {
            stepStartIndex += i + 1
            return false
          }
          // return
          //   const legSteps = steps.slice(stepStartIndex, i)
          //   orderedSteps.push(legSteps)
          //   stepStartIndex = i + 1
          //   distanceMoved = 0
          //   console.log('created first leg-list: ', stepStartIndex);
          //   distanceMoved += step.distance_moved
          //   return true
          // }
          // console.log('distanceMoved: ', distanceMoved);
        })
        orderedSteps.push(subSteps)
      }

      console.log('orderedSteps: ', orderedSteps);
    }

    return orderedSteps
  }

  render() {
    const { isLoadingSteps, routeFound } = this.state
    const { t } = this.props

    if (isLoadingSteps) {
      return (
        <div className="h-100 d-flex align-items-center">
          <Loader />
        </div>
      )
    } else {
      if (!routeFound) {
        return (
          <div className='w-100 my-8 d-flex align-items-center justify-content-center'>
            <div className="mx-auto p-4">
              <h1>{t('route not found')}</h1>
              <p>
                {t('something went wrong')} 
              </p>
              <a href="mailto:info@memomove.eu">{t('contact support')}</a>
            </div>
          </div>
        )
      } else {
        return (
          <div className="h-100 w-100 d-flex">
            <div className="position-relative" style={{ width: '80vw' }}>
              <RouteInfo
                route={{
                  description: this.state.description,
                  internal_distance: this.state.internal_distance
                }}
              />
              <div id="cycle" className="h-100 w-100 position-relative">
                <StreetViewPlayer
                  id={this.props.id}
                  steps={this.state.steps}
                  defaultSpeed={this.state.default_speed}
                />
              </div>
            </div>

            <div className="border-start h-100" style={{ width: '20vw' }}>
              <RouteTrajectory
                steps={this.state.steps}
                legs={this.state.legs}
                orderedSteps={this.state.orderedSteps}
              />
            </div>
          </div>
        )
      }
    }
  }
}
