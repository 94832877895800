import { useEffect, useCallback, useState, useRef } from "react";
import MemoStore from "../../../stores/MemoStore";

export default function Timer({ timerInterval, pauseTimer }) {
  const [ timerValue, setTimerValue ] = useState('00:00')
  const totalSeconds = useRef(0)

  const pad = useCallback((n) => {
    return (10 > n) ? ("0" + n) : n;
  }, [])

  const updateTimer = useCallback(() => {
    const hour = Math.floor(totalSeconds.current / 3600)
    const minute = Math.floor((totalSeconds.current - hour * 3600) / 60)
    const seconds = totalSeconds.current - (hour * 3600 + minute * 60)
  
    let currentValue
    if (0 < hour) {
      currentValue = pad(hour) + ':' + pad(minute) + ':' + pad(seconds)
    } else {
      currentValue = pad(minute) + ':' + pad(seconds);
    }

    setTimerValue(currentValue)
  }, [pad])

  const resumeTimer = useCallback(() => {
    // console.log('resumeTimer...: ', timerInterval.current);
    clearInterval(timerInterval.current)
    timerInterval.current = setInterval(() => {
      totalSeconds.current += 1
      updateTimer()
    }, 1000);
  }, [timerInterval, totalSeconds, updateTimer] )

  const togglePause = useCallback(() => {
    // console.log('togglePause...');
    const paused = MemoStore.getPaused()
    // console.log('paused: ', paused);
    if (paused) {
      pauseTimer()
    } else {
      resumeTimer()
    }
  }, [pauseTimer, resumeTimer])

  useEffect(() => {
    resumeTimer()

    MemoStore.addPauseChangeListener(togglePause)

    return () => {
      MemoStore.removePauseChangeListener(togglePause)
    }
  }, [timerInterval, updateTimer, togglePause, resumeTimer])

  const divStyle = {
    // position: 'absolute',
    // zIndex: 10,
    right: 'calc( 62.5% + 5px )',
    // bottom: '10px',
    fontFamily: 'Verdana, Geneva, sansSerif',
    fontWeight: 'bold',
    fontStyle: 'normal',
    // fontSize: '45px',
    // lineHeight: 'normal',
    opacity: '0.75',
    backgroundColor: '#e7e7e7',
    borderRadius: '7px',
  };

  return (
    <div className="px-2 fs-2 mb-2" style={divStyle}>
      {timerValue}
    </div>
  );
}
