import { Link } from 'react-router-dom'
import { truncateString, capitalizeFirstLetter } from './Helpers'
import { useTranslation } from "react-i18next";

export default function Header({ headerClasses, nav_link_classes, organisation, user, handleLogout }) {
  const { t } = useTranslation();

  return (
    <header className={headerClasses}>
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
            <img src='/static/img/logo_website.png' alt='Logo Memomove' />
            <h4 className='ms-2 mb-0'>
              User { t("portal") }
            </h4>
          </Link>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 ms-4 justify-content-center mb-md-0">
            <li>
              <Link to="/" className={nav_link_classes('Routes')}>Routes</Link>
            </li>
          </ul>

          <div className='d-flex align-items-center'>
            <div className='mx-4'>
              <b>{ t('Organisation') }:</b> {truncateString(organisation.name, 32)}
            </div>
          </div>

          <div className='d-flex align-items-center ms-4'>
            {user.firstname}

            <button onClick={() => handleLogout()} key="logoutPopup" className='ms-4 btn btn-sm btn-outline-secondary'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 12h7m0 0-3 3m3-3-3-3M19 6V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}