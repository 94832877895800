import { useCallback, useEffect, useState } from "react";
import PoiStore from "../../../stores/PoiStore";
import MemoStore from "../../../stores/MemoStore";

export default function PoiButton() {
  const [ show, setShow ] = useState(false)

  const showPois = useCallback(() => {
    MemoStore.setPaused(true)
    PoiStore.showPoi(true)
  }, [])

  const checkPois = useCallback(() => {
    const pois = PoiStore.getPois()
    setShow(pois.length > 0)

    if (pois.length > 0) {
      setTimeout(() => {
        showPois()
      }, 1000)
    }
  }, [showPois])

  useEffect(() => {
    PoiStore.addPoisListener(checkPois)

    return () => {
      PoiStore.removePoiListener(checkPois)
    }
  }, [checkPois])

  return (
    <>
      <button
        onClick={showPois}
        className={`${show ? '' : 'd-none '}btn btn-primary`}
      >
        Show POIs
      </button>
    </>
  )
}