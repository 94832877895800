import React from 'react'
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route } from 'react-router-dom'
import App from './components/App'

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS
      ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload.account;
      
      if (!msalInstance.getActiveAccount()) {
        // console.log('setting account...');
        msalInstance.setActiveAccount(account);
      }
    }

    // if (event.eventType === EventType.LOGIN_SUCCESS) {
    //   console.log('Only on LOGIN_SUCCESS');

    //   const account = msalInstance.getActiveAccount();
    //   console.log('account: ', account);
    // }
  });

  const provider = (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={
          <App
            pca={msalInstance}
          />
        } />
      </Routes>
    </BrowserRouter>
  )
  
  const root = createRoot(document.getElementById('app'));
  root.render(provider)
})
