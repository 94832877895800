import { toast } from 'react-toastify'
import moment from 'moment'

export const pageLoader = (
  <div className='w-100 my-8 d-flex align-items-center justify-content-center'>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateString(str, num) {
  if (str.length <= num) return str
  return str.slice(0, num) + '...'
}

export function prepareAccounts(accounts) {
  return accounts.map((account) => {
    return {
      ...account,
      end_date: moment(account.end_date).local().format('DD/MM/YYYY')
    }
  })
}

export function prepareRoutes(routes) {
  if (routes === null) return []
  
  return routes.map((route) => {
    return {
      ...route,
      last_run: moment(route.last_run).local().format('DD/MM/YYYY HH:mm:ss')
    }
  })
}

export function submitSuccess(response) {
  return response.status === 200 && response.data.success
}

export function submitError(response) {
  // return response.status !== 200 && !response.data.success
  return response.status !== 200 && !response.ok
}

export function successToast(text) {
  return toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function errorToast(text) {
  return toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function createSelectOptionsList(input, prompt) {
  let output = input.map((line) => {
    return { key: line.code, value: line.code, name: `${line.name} - ${line.code.toUpperCase()}` }
  })
  output.unshift({ key: '', value: '', name: prompt })
  return output
}

export function destinationName(address) {
  const parts = address.split(',')
  
  return parts.slice(0, -1).join(', ')
}

// export const accountTypes = [
//   { key: 'U', value: 'User' },
//   { key: 'A', value: 'Admin' },
//   { key: 'M', value: 'Manager' },
// ]

// export const accountTypesOptionsList = [
//   { key: '', value: '', name: 'Select a account type' },
//   { key: 'U', value: 'U', name: 'User' },
//   { key: 'A', value: 'A', name: 'Admin' }
// ]

// export const licenseTypes = {
//   'B': 'Bronze',
//   'S': 'Silver',
//   'G': 'Gold'
// }

// export function communityValue(community) {
//   return community === 'Sh' ? 'Shared' : 'Private'
// }

// export function licenseTypes() {
//   return [
//     { key: 'B', value: 'Bronze' },
//     { key: 'Z', value: 'Silver' },
//     { key: 'G', value: 'Gold' }
//   ]
// }

// export function licenseType(type) {
//   let license = licenseTypes().find(license => license.key === type)
//   if (license === undefined) return license

//   return license.value
// }
