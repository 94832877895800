import { useState, useMemo, useEffect, useRef } from "react";
import { b2cPolicies, loginRequest } from "../authConfig";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

// MSAL imports
import { EventType, InteractionType, InteractionStatus, InteractionRequiredAuthError, ClientAuthError } from "@azure/msal-browser";

import Pages from './Pages'
import Loader from "./Views/Loader";
import { ErrorComponent } from "./Views/ErrorComponent";
import Register from "./Register";

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom"


export default function Authorizer() {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // const [ authorized, setAuthorized] = useState(false)
  // const [ requestError, setRequestError] = useState(null)
  // const [ status, setStatus ] = useState(null);
  // const [ navPage, setNavPage ] = useState('')

  const location = useLocation()
  
  let activateAccount = useRef(false)

  const signInRequest = useMemo(() => {
    return {
      ...loginRequest,
      authority: location.pathname.startsWith('/invitation') ? b2cPolicies.authorities.signUpSignIn.authority : b2cPolicies.authorities.signIn.authority
    }
  }, [location])

  useEffect(() => {
    // console.log('Authorizer load: ', isAuthenticated);
    // console.log('signInRequest: ', signInRequest);

    const callbackId = instance.addEventCallback((event) => {
      // console.log('pages msal-event: ', event);
      if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth 
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy 
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // console.log('login success?: ', event);
        // if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.signIn) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance.getAllAccounts()
            .find(account =>
              account.idTokenClaims.oid === event.payload.idTokenClaims.oid
              &&
              account.idTokenClaims.sub === event.payload.idTokenClaims.sub
              &&
              // account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
              account.idTokenClaims['tfp'] === b2cPolicies.names.signIn
            );

          let signInFlowRequest = {
            ...loginRequest,
            authority: b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount
          };
  
          // silently login again with the signIn policy
          const acquireTokenSilent = instance.acquireTokenSilent(signInFlowRequest)
          acquireTokenSilent.then(function (response) {
            // call API
            return
          }).catch( function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to interaction required
            if (error instanceof InteractionRequiredAuthError || error instanceof ClientAuthError) {
                instance.acquireTokenPopup(signInFlowRequest).then(function (response) {
                return
                // call API
              }).catch(function (error) {
                  // console.log(error);
              });
            }

            instance.clearCache()
            instance.loginRedirect(signInRequest)
          });
        }

        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance.getAllAccounts()
            .find(account =>
              account.idTokenClaims.oid === event.payload.idTokenClaims.oid
              &&
              account.idTokenClaims.sub === event.payload.idTokenClaims.sub
              &&
              account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            ...loginRequest,
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount
          };
  
          // silently login again with the signUpSignIn policy
          const acquireTokenSilent = instance.acquireTokenSilent(signUpSignInFlowRequest)
          acquireTokenSilent.then(function (response) {
            // call API
            return
          }).catch( function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to interaction required
            if (error instanceof InteractionRequiredAuthError || error instanceof ClientAuthError) {
              // console.log(error);
              instance.acquireTokenPopup(signUpSignInFlowRequest).then(function (response) {
                // console.log(response);
                return
                // call API
              }).catch(function (error) {
                  // console.log(error);
              });
            }

            instance.clearCache()
            instance.loginRedirect(signInRequest)
          });
        }

        if (event.payload.state === 'activate_account') {
          activateAccount.current = true
        }
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.clearCache()
        instance.loginRedirect(signInRequest)
      }

      // if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
      //   setStatus("ssoSilent success");
      //   console.log('ssoSilent success...');
      // }
      // console.log('status', status);

      // if (event.eventType === EventType.acquireTokenFailure) {}
    });
    
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }
  }, [instance, signInRequest]);


  const handleLogin = () => {
    // if (loginType === "popup") {
        // instance.loginPopup(loginRequest);
    // } else if (loginType === "redirect") {

        instance.loginRedirect({ ...signInRequest, prompt: 'select_account' });
    // }
  }

  if (location.pathname.startsWith('/invitation')) {
    return (
      <Routes>
        <Route path="/invitation/:token" element={
          <Register
            instance={instance}
            activateAccount={activateAccount.current}
          />
        } />
      </Routes>
    )
  } else if (isAuthenticated) {
    const authRequest = {
      ...signInRequest
    };

    return (
      <MsalAuthenticationTemplate 
        interactionType={InteractionType.Redirect} 
        authenticationRequest={authRequest} 
        errorComponent={ErrorComponent} 
        loadingComponent={Loader}
      >
        <Pages />
      </MsalAuthenticationTemplate>
    )
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    return (
      <div className='text-center mx-auto my-5'>
        <div className='mb-5 d-inline-flex align-items-center'>
          <img src='/static/img/logo_website.png' alt='Logo Memomove' />
          <h4 className='ms-2 mb-0'>
            Memomove
          </h4>
        </div>

        <h5 className="mb-2">User portal</h5>
        <button onClick={() => handleLogin()} key="loginPopup" className='btn btn-outline-dark d-flex align-items-center mx-auto rounded-0'>
          Sign in
        </button>
      </div>
    )
  } else {
    return <Loader />
  }
}