import React from 'react'
import {
  Routes,
  Route } from 'react-router-dom'
import CycleRoutesList from './CycleRoutesList'
import RouteCountryCard from '../Views/RouteCountryCard'
import EmptyCard from '../Views/EmptyCard'
import Loader from '../Views/Loader'
import FetchError from '../Views/FetchError'
import callApi from '../../utils/ApiCall'
import countriesList from '../../countries'

class CycleCountries extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingCountries: true,
      countries: [],
      activeCountry: ''
    }

    this.nav_link_classes = this.nav_link_classes.bind(this)
    this.setActiveCountry = this.setActiveCountry.bind(this)
  }

  async componentDidMount() {
    this.props.setActivePage(this.props.type)

    await this.fetchCountries()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) {
      this.props.setActivePage(this.props.type)
      this.setState({ isLoadingCountries: true }, () => {
        this.fetchCountries()
      })
    }

    if (prevState.activeCountry === this.state.activeCountry && this.state.activeCountry !== '') {
      this.setActiveCountry('')
    }
  }

  nav_link_classes(link) {
    const default_classes = 'd-flex card px-4 py-2 link-underline link-underline-opacity-0 col-2 align-items-center'
    const active_class = ' text-bg-primary'
    return this.state.activeCountry === link ? default_classes + active_class : default_classes
  }

  async fetchCountries() {
    let url

    if (this.props.type === 'Free') {
      url = `/api/routes/countries/free`
    } else {
      url = `/api/routes/countries/${this.props.urlSuffix}`
    }

    const that = this
    await callApi(url)
    // await axios.get(`${process.env.REACT_APP_API_URL}${url}`)
      .then(function (response) {
        let countries = []
        const availableCountries = response.data.countries
        if (availableCountries.length > 0) {
          availableCountries.forEach(({ country_code, route_count }) => {
            const c = countriesList.find((c) => c.code === country_code )
            countries.push({
              country_code, 
              country_name: c.name,
              route_count
            })
          });
        }


        that.setState({ countries, isLoadingCountries: false })
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        that.setState({ isLoadingCountries: false, fetchError: true })
      });
  }

  setActiveCountry(country) {
    this.setState({ activeCountry: country })
  }

  render() {
    const { type, authType, t } = this.props
    const { isLoadingCountries, fetchError, countries } = this.state
    const typeName = type.toLowerCase().split(' ').join('/')

    return (
      <div className='mt-4'>
        {isLoadingCountries ? (
          <Loader />
        ) : (
          <>
            {fetchError ? (
              <FetchError />
            ) : (
              <>
                {countries.length === 0 ? (
                  <div className='my-4'>
                    <EmptyCard
                      emptyText={ t('no routes for this type') }
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center mb-4">
                      {countries.map((country, index) => {
                        return <RouteCountryCard
                          key={index}
                          name={country.country_name}
                          code={country.country_code}
                          url={`/${typeName}/${country.country_code.toLowerCase()}`}
                          route_count={country.route_count}
                          nav_link_classes={this.nav_link_classes}
                        />
                      })}
                    </div>
      
                    <Routes>
                      {countries.map((country, index) => (
                        <Route
                          key={'country_route_' + index}
                          path={country.country_code.toLowerCase() + '/:page?'}
                          element={
                            <CycleRoutesList
                              name={country.country_name}
                              type={type}
                              routeCount={country.route_count}
                              baseRouteUrl={`/${typeName}/${country.country_code.toLowerCase()}`}
                              countryCode={country.country_code}
                              setActiveCountry={this.setActiveCountry}
                              authType={authType}
                              orgUuid={this.props.orgUuid}
                            />
                          }
                        />
                      ))}
                    </Routes>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }
}

export default CycleCountries
