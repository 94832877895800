import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../Helpers';

export default function RouteCategoryCard({ name, url, countries_count, route_count, nav_link_classes, t }) {
  return (
    <Link to={url} className={nav_link_classes(name)}>
      <b>{capitalizeFirstLetter(t(name.toLowerCase()))}</b>
      <small>
        {capitalizeFirstLetter(t('countries'))}: {countries_count}
      </small>
      <small>
        Routes: {route_count}
      </small>
    </Link>
  )
}
